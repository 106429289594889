import React from "react";
import "./SlidingText.css";

const SlidingText = () => {
  return (
    <div className="sliding-container">
      <div className="sliding-text">
        Art-inspired streetwear that turns heads and breaks rules. Wear the statement!
        For the dreamers, the creators, and the rule-breakers – Avant Divine is yours
      </div>
    </div>
  );
};

export default SlidingText;
