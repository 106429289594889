// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliding-container {
  width: 100%;
  overflow: hidden;
  background-color: black;
}

.sliding-text {
  display: inline-block;
  white-space: nowrap;
  animation: slide 10s linear infinite;
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  color: white;
  padding: 15px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .sliding-text {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .sliding-text {
    font-size: 0.9rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SlidingText/SlidingText.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,oCAAoC;EACpC,gCAAgC;EAChC,eAAe;EACf,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".sliding-container {\n  width: 100%;\n  overflow: hidden;\n  background-color: black;\n}\n\n.sliding-text {\n  display: inline-block;\n  white-space: nowrap;\n  animation: slide 10s linear infinite;\n  font-family: \"Arial\", sans-serif;\n  font-size: 1rem;\n  color: white;\n  padding: 15px;\n}\n\n@keyframes slide {\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n\n@media (max-width: 768px) {\n  .sliding-text {\n    font-size: 1rem;\n  }\n}\n@media (max-width: 480px) {\n  .sliding-text {\n    font-size: 0.9rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
