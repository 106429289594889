// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 640px) {
  .gapcont {
    gap: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Header/Navbar.css"],"names":[],"mappings":"AAAA;EACE;IACE,MAAM;EACR;AACF","sourcesContent":["@media (max-width: 640px) {\n  .gapcont {\n    gap: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
